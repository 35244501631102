import React from "react"

import { Col, Form } from "react-bootstrap"

function SearchTypeField(props) {
    return (
        <>
            {/* Property searchType Field */}
            <Col md={12}>
                <Form.Group className="form-input-group">
                    <Form.Label htmlFor="searchType">
                        <span className="text-semi-bold text-sm">
                            You are looking to
                        </span>
                    </Form.Label>
                    <div>
                        <Form.Check
                            inline
                            label="Buy"
                            value="Buy"
                            type="radio"
                            checked={props.formik.values.searchType === "Buy"}
                            onChange={(e) =>
                                props.formik.setFieldValue("searchType", "Buy")
                            }
                        />
                        <Form.Check
                            inline
                            label="Rent"
                            value="Rent"
                            type="radio"
                            checked={props.formik.values.searchType === "Rent"}
                            onChange={(e) =>
                                props.formik.setFieldValue("searchType", "Rent")
                            }
                        />
                    </div>

                    {props.formik.errors.searchType &&
                    props.formik.touched.searchType ? (
                        <p className="text-danger text-sm">
                            {props.formik.errors.searchType}
                        </p>
                    ) : null}
                </Form.Group>
            </Col>
            {/* Property searchType Field End */}
        </>
    )
}

export default SearchTypeField
