import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { Formik } from "formik"
import * as Yup from "yup"
import { Form as FormikForm } from "formik"
import { Row } from "react-bootstrap"

import { setFindHomeDataOne } from "@/slices/findHomeModalSlice"

import SearchTypeField from "@/fields/searchTypeField"
import HouseToSellField from "@/fields/houseToSellField"
import PriceField from "@/fields/priceField"
import NextButton from "@/fields/nextButton"

function FindMeAHomeStepOne(props) {
    const dispatch = useDispatch()

    // The selector to retrieve the find home form from state.
    const { formData } = useSelector((state) => state.findHomeModal)

    // The schema used for validation of the find home form input.
    const FindHomeSchema = Yup.object().shape({
        houseToSell: Yup.string()
            .oneOf(["No", "Yes"], "Must be one of 'Yes', or 'No'.")
            .required("This field is required."),
        searchType: Yup.string()
            .oneOf(["Buy", "Rent"], "Must be one of 'Buy', or 'Rent'.")
            .required("This field is required."),
    })

    return (
        <>
            <Formik
                initialValues={formData}
                validationSchema={FindHomeSchema}
                // Send the form data to the backend
                onSubmit={async (values) => {
                    dispatch(setFindHomeDataOne(values))
                }}
            >
                {(formik) => (
                    <FormikForm>
                        <Row>
                            <SearchTypeField formik={formik} />
                            <HouseToSellField formik={formik} />
                        </Row>

                        <Row>
                            <PriceField
                                searchType={formik.values.searchType}
                                level="Min"
                                formik={formik}
                            />
                            <PriceField
                                searchType={formik.values.searchType}
                                level="Max"
                                formik={formik}
                            />
                        </Row>

                        <NextButton formik={formik} />
                    </FormikForm>
                    // Find Home Form End
                )}
            </Formik>
        </>
    )
}

export default FindMeAHomeStepOne
