import React from "react"

import { Col, Form } from "react-bootstrap"

function HouseToSellField(props) {
    return (
        <>
            {/* House to sell Field */}
            <Col md={12}>
                <Form.Group className="form-input-group">
                    <Form.Label htmlFor="houseToSell">
                        <span className="text-semi-bold text-sm">
                            Do you have a house to sell?
                        </span>
                    </Form.Label>

                    <div>
                        <Form.Check
                            inline
                            label="No"
                            value="No"
                            type="radio"
                            checked={props.formik.values.houseToSell === "No"}
                            onChange={(e) =>
                                props.formik.setFieldValue("houseToSell", "No")
                            }
                        />
                        <Form.Check
                            inline
                            label="Yes"
                            value="Yes"
                            type="radio"
                            checked={props.formik.values.houseToSell === "Yes"}
                            onChange={(e) =>
                                props.formik.setFieldValue("houseToSell", "Yes")
                            }
                        />
                    </div>

                    {props.formik.errors.houseToSell &&
                    props.formik.touched.houseToSell ? (
                        <p className="text-danger text-sm">
                            {props.formik.errors.houseToSell}
                        </p>
                    ) : null}
                </Form.Group>
            </Col>
            {/* House to sell Field End */}
        </>
    )
}

export default HouseToSellField
