import React from "react"

import { Field } from "formik"

import { Col, Form, InputGroup } from "react-bootstrap"

function PriceField(props) {
    const fieldName = props.level + props.searchType

    return (
        <>
            {/* Price Field */}
            <Col xs={6} className="ps-md-2">
                <Form.Group className="form-input-group">
                    <Form.Label htmlFor={fieldName}>
                        <span className="text-semi-bold text-sm">
                            {props.level + " price"}
                        </span>
                    </Form.Label>

                    <InputGroup
                        className={
                            props.placement === "Property List Search"
                                ? ""
                                : "shadow-sm"
                        }
                    >
                        <Field
                            as="select"
                            id={fieldName}
                            name={fieldName}
                            className="form-control form-select"
                            type="button"
                        >
                            {props.searchType === "Buy" ? (
                                <>
                                    <option value="">No {props.level}</option>

                                    <option value="50000">£50,000</option>
                                    <option value="60000">£60,000</option>
                                    <option value="70000">£70,000</option>
                                    <option value="80000">£80,000</option>
                                    <option value="90000">£90,000</option>
                                    <option value="100000">£100,000</option>
                                    <option value="125000">£125,000</option>
                                    <option value="150000">£150,000</option>
                                    <option value="175000">£175,000</option>
                                    <option value="200000">£200,000</option>
                                    <option value="225000">£225,000</option>
                                    <option value="250000">£250,000</option>
                                    <option value="275000">£275,000</option>
                                    <option value="300000">£300,000</option>
                                    <option value="350000">£350,000</option>
                                    <option value="400000">£400,000</option>
                                    <option value="450000">£450,000</option>
                                    <option value="500000">£500,000</option>
                                    <option value="600000">£600,000</option>
                                    <option value="700000">£700,000</option>
                                    <option value="800000">£800,000</option>
                                    <option value="900000">£900,000</option>
                                    <option value="1000000">£1,000,000</option>
                                    <option value="1250000">£1,250,000</option>
                                    <option value="1500000">£1,500,000</option>
                                    <option value="1750000">£1,750,000</option>
                                    <option value="2000000">£2,000,000</option>
                                    <option value="2500000">£2,500,000</option>
                                    <option value="3000000">£3,000,000</option>
                                    <option value="3500000">£3,500,000</option>
                                    <option value="4000000">£4,000,000</option>
                                    <option value="5000000">£5,000,000</option>
                                </>
                            ) : (
                                <>
                                    <option value="">No {props.level}</option>
                                    <option value="100">£100 pcm</option>
                                    <option value="150">£150 pcm</option>
                                    <option value="200">£200 pcm</option>
                                    <option value="250">£250 pcm</option>
                                    <option value="300">£300 pcm</option>
                                    <option value="350">£350 pcm</option>
                                    <option value="400">£400 pcm</option>
                                    <option value="450">£450 pcm</option>
                                    <option value="500">£500 pcm</option>
                                    <option value="600">£600 pcm</option>
                                    <option value="700">£700 pcm</option>
                                    <option value="800">£800 pcm</option>
                                    <option value="900">£900 pcm</option>
                                    <option value="1000">£1,000 pcm</option>
                                    <option value="1250">£1,250 pcm</option>
                                    <option value="1500">£1,500 pcm</option>
                                    <option value="1750">£1,750 pcm</option>
                                    <option value="2000">£2,000 pcm</option>
                                    <option value="2250">£2,250 pcm</option>
                                    <option value="2500">£2,500 pcm</option>
                                    <option value="2750">£2,750 pcm</option>
                                    <option value="3000">£3,000 pcm</option>
                                    <option value="3500">£3,500 pcm</option>
                                    <option value="4000">£4,000 pcm</option>
                                </>
                            )}
                        </Field>
                    </InputGroup>
                    {props.formik.errors.MaxBuy &&
                    props.formik.touched.MaxBuy &&
                    fieldName === "MaxBuy" ? (
                        <p className="text-danger text-sm">
                            {props.formik.errors.MaxBuy}
                        </p>
                    ) : null}
                    {props.formik.errors.MaxRent &&
                    props.formik.touched.MaxRent &&
                    fieldName === "MaxRent" ? (
                        <p className="text-danger text-sm">
                            {props.formik.errors.MaxRent}
                        </p>
                    ) : null}
                </Form.Group>
            </Col>
            {/* Price Field End */}
        </>
    )
}

export default PriceField
