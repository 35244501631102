import React from "react"
import dynamic from "next/dynamic"
import { useSelector, useDispatch } from "react-redux"

import { Modal } from "react-bootstrap"

import * as ga from "@/ga"

import {
    toggleFindHomeModal,
    resetFindHomeModal,
} from "@/slices/findHomeModalSlice"

import FindMeAHomeStepOne from "@/forms/findMeAHomeStepOne"
import FindMeAHomeStepTwo from "@/forms/findMeAHomeStepTwo"

const SuccessToast = dynamic(() => import("@/toasts/successToast"))
const ErrorToast = dynamic(() => import("@/toasts/errorToast"))

function FindMeAHomeModal() {
    const dispatch = useDispatch()

    const { cookieConsent } = useSelector((state) => state.cookie)

    const generateLead = () => {
        if (cookieConsent === "true") {
            ga.event({
                action: "generate_lead",
            })
        }
    }

    // The selector to retrieve the find me a home form status from state.
    // The selector to retrieve the find me a home form position from state.
    // The selector to retrieve whether the find me a home should be
    // shown or hidden from state.
    const { showFindHomeModal, formPosition, status } = useSelector(
        (state) => state.findHomeModal,
    )

    return (
        <>
            <SuccessToast
                status={status}
                resetModal={() => {
                    dispatch(resetFindHomeModal())
                    generateLead()
                }}
            />
            <ErrorToast
                status={status}
                resetModal={() => dispatch(resetFindHomeModal())}
            />
            {/* Find Me a Home Form Modal */}
            <Modal
                show={showFindHomeModal}
                onHide={() => dispatch(toggleFindHomeModal(false))}
                fullscreen="lg"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Modal.Title>
                        <h2 className="text-center">Find me a home</h2>
                        <p className="text-center">
                            Simply enter your details and a member of our team
                            will be in touch shortly to help you find your new
                            home.
                        </p>
                    </Modal.Title>

                    {/* Fine me a Home Form */}
                    {formPosition ? (
                        <>
                            <h3 className="mt-4 text-end me-2 step-count">
                                Step 2 of 2
                            </h3>
                            <hr className="mb-2" />
                            <FindMeAHomeStepTwo />
                        </>
                    ) : (
                        <>
                            <h3 className="mt-4 text-end me-2 step-count">
                                Step 1 of 2
                            </h3>
                            <hr className="mb-2" />
                            <FindMeAHomeStepOne />
                        </>
                    )}
                </Modal.Body>
            </Modal>
            {/* Fine me a Home Form Modal End */}
        </>
    )
}

export default FindMeAHomeModal
