import React from "react"

import { Button, Row, Col } from "react-bootstrap"

function NextButton(props) {
    return (
        <>
            {/* Submit Button */}
            <Row>
                <Col>
                    <Col
                        as={Button}
                        xs={12}
                        lg={6}
                        variant="warning"
                        size="lg"
                        type="submit"
                    >
                        <span className="text-bold text-white">Next</span>
                    </Col>
                </Col>
            </Row>
            {/* Submit Button End */}
        </>
    )
}

export default NextButton
